/** @jsxImportSource theme-ui */
import * as React from "react";
import { Container } from "theme-ui";

const Contact = ({ data }) => {
  const { heading, telephoneNumbers, address, mapEmbed, anchor } = data;

  return (
    <div id={anchor}>
      <Container
        sx={{
          pt: "8rem",
          pb: "4rem",
        }}
      >
        <h3
          sx={{
            mb: "1rem",
          }}
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <p>
          {telephoneNumbers.map((number, index) => (
            <React.Fragment key={index}>
              <a
                sx={{
                  color: "currentcolor",
                  textDecoration: "none",
                }}
                href={`tel:${number}`}
              >
                {number}
              </a>
              {index + 1 !== telephoneNumbers.length && (
                <span
                  sx={{
                    px: ".25rem",
                  }}
                >
                  /
                </span>
              )}
            </React.Fragment>
          ))}
        </p>
        <div
          sx={{ pt: ".5rem" }}
          dangerouslySetInnerHTML={{ __html: address }}
        />
      </Container>
      <div
        sx={{
          height: "30rem",
        }}
        dangerouslySetInnerHTML={{ __html: mapEmbed.html }}
      ></div>
    </div>
  );
};

export default Contact;
